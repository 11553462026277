<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="primary--text-kh">{{$t('caption.activityHistory')}} </span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="userLogHeader" :items="userLogData">
          <template #[`item.modidate`]="{item}">
            <span v-if="item.modidate">{{formatDate(item.modidate)}}</span>
          </template>
        </v-data-table>

      </v-card-text>
    </v-card>
    <v-card class="my-5">
      <v-card-title>
        <span class="primary--text-kh">{{$t('menu.staffInfo')}} </span>
      </v-card-title>
      <v-card-text>
        <v-btn outlined rounded class="mr-5 mb-3" to="/staff">
          <v-icon dark color="primary">
            {{icons.mdiAccountSearch}}
          </v-icon> Staff List
        </v-btn>

        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiPlus}}
          </v-icon> Create new staff
        </v-btn>

        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiTransfer}}
          </v-icon> Staff transfer
        </v-btn>

        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiChartAreaspline}}
          </v-icon> User Reports
        </v-btn>

      </v-card-text>
    </v-card>
    <v-card class="my-5">
      <v-card-title>
        <span class="primary--text-kh">{{$t('menu.caseManagement')}}</span>
      </v-card-title>
      <v-card-text>
        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiFileDocumentMultiple}}
          </v-icon> Case List
        </v-btn>
        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiPlus}}
          </v-icon> Create new case
        </v-btn>
        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiChartAreaspline}}
          </v-icon> Case Reports
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <span class="primary--text-kh">{{$t('caption.systemManagement')}}</span>
      </v-card-title>
      <v-card-text>
        <v-btn outlined rounded class="mr-5 mb-3" to="/admin/users">
          <v-icon dark color="primary">
            {{icons.mdiAccountSettings}}
          </v-icon> User Administration
        </v-btn>
        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiCameraAccount}}
          </v-icon> Job Positions
        </v-btn>
        <v-btn outlined rounded class="mr-5 mb-3">
          <v-icon dark color="primary">
            {{icons.mdiMedal}}
          </v-icon> Honorable Medals
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SystemService from '@/services/service.system'
import moment from 'moment'

import {
  mdiPlus,
  mdiPencil,
  mdiDelete,
  mdiSettingsHelper,
  mdiTransfer,
  mdiFileDocumentMultiple,
  mdiMedal,
  mdiProfessionalHexagon,
  mdiCameraAccount,
  mdiAccountSettings,
  mdiAccountSearch,
  mdiChartAreaspline
} from '@mdi/js'

export default {
  name: 'dashboard',
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
        mdiSettingsHelper,
        mdiTransfer,
        mdiFileDocumentMultiple,
        mdiMedal,
        mdiProfessionalHexagon,
        mdiCameraAccount,
        mdiAccountSettings,
        mdiAccountSearch,
        mdiChartAreaspline
      },
      userLogHeader: [
        { text: 'Timestamp', value: 'modidate' },
        { text: 'User', value: 'user' },
        { text: 'Action ', value: 'action' },
        { text: 'Affected Object', value: 'object' }
      ],
      userLogData: []
    }
  },
  computed: {},

  mounted() {
    SystemService.getUserLogData().then(response => {
      this.userLogData = response.data
    })
  },
  methods: {
    formatDate(date) {
      if (date) return moment(date).format('DD-MM-YYYY hh:mm:ss')
      else {
        return ''
      }
    }
  }
}
</script>
